<template>
    <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/UserManage/ProblemFeedback"
          > 问题反馈 /</router-link>
          <span class="crumbs_item crumbs_last"> {{operation==0?'详情':'回复'}}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <span class="all_left_name title-blod">实名信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>姓名：<span>{{model.name}}</span></p>
            <p>手机号：<span>{{model.mobile}}</span></p>
            <p>时间：<span>{{model.backTime}}</span></p>
          </div>
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name title-blod">问题描述</span>
        <div class="bgDiv">
          <p>{{model.content}}</p>
        </div>
      </div>
      <div class="Up_Down_inner" v-show="model.imgList.length">
        <span class="all_left_name title-blod">图片反馈</span>
        <div class="bgDiv">
          <img
              v-for="(item,index) in model.imgList"
              :key="index"
              class="photo"
              :src="item"
              alt=""
            >
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name title-blod">回复</span>
        <div class="bgDiv">
           <a-textarea
              :read-only="isReadonly"
              placeholder="回复内容"
              :rows="4"
              style="resize:none;"
              v-model="model.reply"
            />
        </div>
      </div>
    </div>
    <div class="button">
      <a-button v-if="operation==0" type="primary" class="btn" @click="onSave()">确认</a-button>
      <a-button class="all_boder_btn btn" @click="$router.go(-1)">取消</a-button>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {}, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      isReadonly: '',
       model: {
        backTime: "",
        content: "",
        imgList: "",
        mobile: "",
        name: "",
        problemId: "",
        reply: "",
        userId: "",
        userName: "",
      },
    };
  }, // 事件处理器
  methods: {
    onSave(){
      this.$ajax({
        method: "put",
        url: "/hxclass-management/problem/manage/reply",
        params: {
          problemId: this.model.problemId,
          reply: this.model.reply,
          userId: this.model.userId,
        },
      }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$router.push('/admin/UserManage/ProblemFeedback')
          }
        })
        .catch((error) => {
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    getDetailList() {
      this.$ajax({
        url: "/hxclass-management/problem/manage/" + this.id,
      })
        .then((res) => {
          if (res.code == 200 && res.success) {
            this.model = res.data;
            this.isReadonly = this.operation == 0 ? false : true;
            // console.log(this.model)
          }
        })
        .catch((error) => {
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.operation = this.$route.query.operation;
    this.id = this.$route.query.id;
    this.getDetailList();
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
a {
  color: #333333;
}
.title-blod {
   margin-top: 16px;
   font-size: 14px;
   font-family: PingFang SC-Medium, PingFang SC;
   font-weight: 600;
   color: #000000;
   line-height: 16px;
}
.photo {
  margin-right: 24px;
   width: 120px;
}
.title-blod {
  margin-bottom: 16px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
}
.Up_Down_inner {
  margin-bottom: 10px;
}
.bgDiv {
  .line_item {
    display: flex;
    margin-bottom: 24px;
    p {
      width: calc(100% / 3);
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      span {
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .line_item:last-child {
    margin-bottom: 0;
  }
}
.button {
  .btn {
    margin: 24px 14px;
  }
}
</style>
